import React from 'react'
import PropTypes from 'prop-types'
import './ClientSectionFullWidth.css'
import Button from '../Button/Button'

const ClientSection = ({ className }) => {
return (

<div className={`clients-fullwidth flex w-full flex-col justify-center ${className} `} >
        <div className='flex w-full flex-wrap justify-center'>
          <div className='flex w-full justify-center text-center'>
            <p className='category-title'>Unsere Kunden</p>
          </div>
        </div>
        <div class="preload">
          <div class="logo-ref-goeuro"></div>
          <div class="logo-ref-thwildau"></div>
          <div class="logo-ref-rtv"></div>
          <div class="logo-ref-franzwach"></div>
          <div class="logo-ref-haba"></div>
        </div>
        <div className="flex wrapper-logos-ref w-full">
          <div className='flex logos-ref'>
            <div className="hidden md:block logo-link ml-16 self-center items-center"><Button to='/projects' withArrow className='p-4 xs:mt-8 md:mt-0 btn-color-text'>mehr Infos</Button></div>
            <div className="w-80 ml-16 logo-entry logo-ref-franzwach">
                <div className="logo-container w-full"></div>
                <p className="logo-subline">Einer der Top 25 Personaldienstleister.</p>
              </div>
              <div className="logo-entry logo-ref-haba">
                <div className="logo-container w-full"></div>
                <p className="logo-subline">Tochter des Spielzeugherstellers HABA.</p>
              </div>
              <div className="logo-entry logo-ref-rtv">
                <div className="logo-container w-full"></div>
                <p className="logo-subline">Tochter des Bertelsmann Konzerns.</p>
              </div>
              <div className="logo-entry logo-ref-thwildau">
                <div className="logo-container w-full"></div>
                <p className="logo-subline">Größte Fachhochschule Brandenburgs.</p>
              </div>
              <div className="logo-entry logo-ref-archipinion">
                <div className="logo-container w-full"></div>
                <p className="logo-subline">Videoplattform des DETAIL Verlags für Architektur.</p>
              </div>
              <div className="logo-entry logo-ref-goeuro">
                <div className="logo-container w-full"></div>
                <p className="logo-subline">Bahn-, Bus- und Flugtickets vergleichen und buchen.</p>
              </div>
              <div className="w-80 ml-16 logo-entry logo-ref-franzwach">
                <div className="logo-container w-full"></div>
                <p className="logo-subline">Einer der Top 25 Personaldienstleister.</p>
              </div>
              <div className="logo-entry logo-ref-haba">
                <div className="logo-container w-full"></div>
                <p className="logo-subline">Tochter des Spielzeugherstellers HABA.</p>
              </div>
              <div className="logo-entry logo-ref-rtv">
                <div className="logo-container w-full"></div>
                <p className="logo-subline">Tochter des Bertelsmann Konzerns.</p>
              </div>
              <div className="logo-entry logo-ref-thwildau">
                <div className="logo-container w-full"></div>
                <p className="logo-subline">Größte Fachhochschule Brandenburgs.</p>
              </div>
              <div className="logo-entry logo-ref-archipinion">
                <div className="logo-container w-full"></div>
                <p className="logo-subline">Videoplattform des DETAIL Verlags für Architektur.</p>
              </div>
              <div className="logo-entry logo-ref-goeuro">
                <div className="logo-container w-full"></div>
                <p className="logo-subline">Bahn-, Bus- und Flugtickets vergleichen und buchen.</p>
              </div>
          </div>
        </div>
      </div>
    )
}

export default ClientSection
import React from 'react'
import PropTypes from 'prop-types'

import './HeaderFrontpage.css'
import Button from '../Button/Button'

const Header = ({ className, titleBig, teaserText ,color, textcolor, children }) => {
  return (
    <>
      <header className={`w-full px-4 md:px-8 pb-6 lg:pb-16 pt-0 md:pt-16 flex justify-center v4 bg-${color} ${className}`}>
        <div className={`flex w-full max-w-6xl flex-wrap`}>
          <div className={`flex w-full flex-wrap md:w-6/12 xs:mt-8 md:mt-0 md:pr-8`}>
              <h1 className={`stage-title text-6xl w-full self-start hyphens-manual ${textcolor}`}>Per Anhalter zur Digitali&shy;sierung.</h1>
              <div id="stage-img-mobile" className="mobile-only block md:hidden">{children}</div>
              
          </div>
          <div className={`flex w-full flex-wrap md:w-6/12 xs:mt-8 md:mt-0 md:pr-8 md:pl-8`}>
              <p className='leading-relaxed text-white w-full xs:mt-0 md:mt-12 md:pr-16 hyphens intro-stage-text'>
              Unsere Mission ist die bei dieser Herausforderung zu unterstützen. Wir sind ein Team aus Entwicklern, Gestaltern und Unternehmen. Wir verstehen, was es heißt ein digitales Produkt zu entwickeln, um ihren Unternehmen Wachstum zu ermöglichen.
              </p>
              <Button to='/projekt-starten' withArrow className='w-max-content self-start p-3 mt-4 mb-8 btn-filled btn-yellow-outline'>Projekt starten</Button>
          </div>
          <div id='stage-img' className='hidden md:block overflow-x-hidden w-full'>
              {children}
          </div>
        </div>
      </header>
    </>
  )
}

Header.propTypes = {
  titleBig: PropTypes.string,
  teaserText: PropTypes.string,
  color: PropTypes.string,
  textcolor: PropTypes.string,
  children: PropTypes.node,
}

Header.defaultProps = {
  color: 'cream',
}

export default Header

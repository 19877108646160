import React from 'react'
import { graphql } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Layout from '../components/Layout/Layout'
import SEO from '../components/seo'
import Header from '../components/Header/HeaderFrontpage2'
import Button from '../components/Button/Button'
import PortfolioTeaser from '../components/PortfolioTeaser/PortfolioTeaser'
import ClientSectionFullWidth from '../components/ClientSectionFullWidth/ClientSectionFullWidth'
import ClientSection from '../components/ClientSection/ClientSection'
import ContactBoxFullWidth from '../components/ContactBoxFullWidth/ContactBoxFullWidth'
import ImgBenefits from '../images/svgs/benefits.svg'
import ImgStageHome from '../images/svgs/stage_home_min2.svg'
import '../components/ServicesOverview/ServicesOverview.css'
import ServiceOverview from '../components/ServicesOverview/ServicesOverview'

const IndexPage = ({ data }) => {
  const { slug, titleStage, titleIntrotext, titleAboutSection, titleAboutTeasertext, titleBenefits, titleBenefitsSection, titlePortfolioSection, titlePortfolio } = data.contentfulFrontpage
  const content_shortIntrotext = data.contentfulFrontpage.childContentfulFrontpageShortIntrotextTextNode.shortIntrotext
  const content_shortAboutTeasertext = data.contentfulFrontpage.childContentfulFrontpageShortAboutTeasertextTextNode.shortAboutTeasertext
  const content_bulletpointsBenefits = data.contentfulFrontpage.childContentfulFrontpageBulletpointsBenefitsRichTextNode.json

  return (
    <Layout>
      
      <SEO title={titleStage} />
      <Header titleBig={titleStage} teaserText={content_shortIntrotext} color='darkgrey' textcolor='text-white' className='stage-home-big'>
        <ImgStageHome />
      </Header>
      <ClientSectionFullWidth className='xs:mb-8 xs:mt-16 md:mb-16 lg:mt-32 lg:mb-16'/>
      <div className='flex w-full xs:mb-0 xs:mt-16 md:mb-16 lg:my-24 px-6 md:px-8 justify-center'>
        <div className='flex w-full max-w-4xl flex-wrap justify-center'>
          <div className='flex flex-col md:w-full xs:mb-8 md:mb-0 max-w-2xl text-center'>
          <p className='category-title'>Das bieten wir</p>
            <h2 className='leading-relaxed'>Ihr Speedboot für Apps, Websites & Product Experience</h2>
          </div>
        </div>
      </div>
      <div className='flex w-full xs:my-16 xs:mt-8 lg:my-4 xs:px-2 sm:px-8 md:px-8 justify-center'>
        <div className='flex w-full max-w-6xl flex-wrap justify-center'>
          <ServiceOverview />
        </div>
      </div>
      <div className='flex w-full xs:my-8 lg:mt-32 lg:mb-20 px-6 md:px-8 lg:px-16 justify-center'>
        <div className='flex w-full max-w-6xl flex-wrap justify-center'>
          <div className='flex flex-col w-full'>
            <p className='category-title'>{titleAboutSection}</p>
          </div>
          <div className='flex flex-col md:w-6/12 max-w-4xl md:pr-8 lg:pr-32 xs:mb-8 md:mb-0 '>
            <h2 className='leading-relaxed'>{titleAboutTeasertext}</h2>
          </div>
          <div className='flex flex-col md:w-6/12 max-w-4xl '>
            <p className=''>{content_shortAboutTeasertext}</p>
            <Button to='/about' withArrow className='p-4 xs:mt-4 md:mt-4 btn-color-text'>mehr zu uns</Button>
          </div>
        </div>
      </div>
      <div className='flex w-full xs:my-16 lg:my-20 px-6 md:px-8 lg:px-16 justify-center'>
        <div className='flex w-full max-w-6xl flex-wrap justify-center'>
          <div className='flex flex-col xs:order-2 md:order-1 md:w-6/12 max-w-4xl pr-8 mt-8 md:-mt-8'>
            <p className=''><ImgBenefits className='w-full illu-benefits'/></p>
          </div>
          <div className='flex flex-col xs:order-1 md:order-2 md:w-6/12 max-w-4xl '>
            <p className='category-title'>{titleBenefitsSection}</p>
            <h2 className='leading-relaxed'>{titleBenefits}</h2>
            <p className='list-benefits mt-8'>{documentToReactComponents(content_bulletpointsBenefits)}</p>
          </div>
        </div>
      </div>

      <div className='flex w-full flex-wrap xs:my-2 lg:my-8 xs:px-4 md:px-8 justify-center '>
        <ContactBoxFullWidth />
      </div>

      <div className='flex w-full flex-wrap xs:my-16 lg:my-32 lg:mb-16 px-0 justify-center '>
        <div className='flex w-full text-center'>
          <span className='category-title mx-auto text-center'>{titlePortfolioSection}</span>
        </div>
        <div className='flex w-full text-center max-w-2xl mt-2'>
          <h2 className='leading-relaxed mx-auto text-center'>{titlePortfolio}</h2>
        </div>
        <div className='flex w-full xs:mt-8 md:mt-20 flex-wrap justify-center'>
          <PortfolioTeaser />
        </div>
        <div className='flex w-full xs:mt-0 md:mt-0 flex-wrap justify-center'>
          <Button to='/projects' withArrow className='p-4 mr-8 xs:mt-8 md:mt-0 btn-color-text'>mehr Projekte</Button>
        </div>
        
      </div>
      <div className="mt-16"></div>
    </Layout>
  )
}

export default IndexPage

export const FrontpageQuery = graphql`
query {
  contentfulFrontpage(slug: {eq: "start"}) {
    slug
    titleStage
    titleIntrotext
    titleAboutSection
    titleAboutTeasertext
    titleBenefitsSection
    titlePortfolioSection
    titlePortfolio
    slug
    childContentfulFrontpageShortIntrotextTextNode {
      shortIntrotext
    }
    childContentfulFrontpageShortAboutTeasertextTextNode {
      shortAboutTeasertext
    }
    titleBenefits
    childContentfulFrontpageBulletpointsBenefitsRichTextNode {
      json
    }
  }
}
`